import React, { useState, useEffect } from 'react';
import { useParams , Link } from 'react-router-dom';
import { collection, query, where, getDocs } from 'firebase/firestore';
import SubscribeForm from './SubscribeForm'
import EmailLinkAuth from './EmailLinkAuth'

function PostPage({db, user}) {
	const [postContent, setPostContent] = useState("");
	const [postTitle, setPostTitle] = useState("");
	const [postAuthor, setPostAuthor] = useState("");
	const [postDate, setPostDate] = useState("");
	const [userIsPaidSubscriber, setUserIsPaidSubscriber] = useState(false);
	const [isSubscriberOnly, setIsSubscriberOnly] = useState(false);
	const [premiumAvailable, setPremiumAvailable] = useState(false);
	const {urlStub} = useParams();

	useEffect(() => {
		async function fetchPost() {
			const postsRef = collection(db, 'posts');
			const q = query(postsRef, where('url-index', '==', urlStub));
			const querySnapshot = await getDocs(q);

			if(!querySnapshot.empty) {
				const postDoc = querySnapshot.docs[0].data();
				setIsSubscriberOnly(postDoc.subscriberOnly);
				setPremiumAvailable(postDoc.premiumAvailable);
				setUserIsPaidSubscriber(false);
				if(user) {
					setPostContent(postDoc.registeredContent);
				}
				else {
					//setPostContent(postDoc.openContent);
					setPostContent(postDoc.registeredContent);
				}
					setPostTitle(postDoc.title);
					setPostAuthor(postDoc.author);
					setPostDate(postDoc.postDate?.toDate().toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric', }));
			} else {
				setPostContent('Post not found');
			}
		}

		fetchPost();
	}, [db, urlStub, user]);


	if(false && isSubscriberOnly && (!user || !user.isPaidSubscriber)) {
		return(
			<div className="article">
			<h1 className="title">Subscriber-Only Content</h1>
			<span className="date">{postDate}</span>
			<div className="metaData">
			<span className="author">{postAuthor}</span>
			</div>
			<p>This content is available only to paid subscribers.</p>
			{!user && <p>Please <Link to="/login">log in</Link> if you're already a subscriber.</p> }
				{user && !user.isPaidSubscriber && <p>To access this content, please <Link to="/subscribe">subscribe</Link>.</p>}
				</div>
			);}




			return (
		<main className="main">
				<article className="article">
				<p style={{color: 'grey'}}>News Alert</p>
				<h1 className="title">{postTitle}</h1>
				<span className="date">{postDate}</span>
				<div className="content" dangerouslySetInnerHTML={{__html: postContent}} />
				<div className="metaData">
				<p><span className="author">{postAuthor}</span></p>
				</div>
				{
					!user &&
					<div style={{marginTop: '30px', padding: '20px', backgroundColor: '#ddd9d9', color: '#383838'}}>

					<h2>Subscribe for free</h2>
					<p>Subscribe for Debt Captial Markets news.</p>
					<SubscribeForm />

					</div>
				}
				</article>
				</main>
			);
	}

	export default PostPage;

